@import 'Variables';
@import 'mediaQueries';

.card{
    margin: 60px auto;

    @include responsive(){
        overflow: hidden;
        margin: 60px auto;
        border-radius: 10px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    max-width: 724px;
    min-width: 100%;
    .card-content{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        min-width: 250px;
        
        align-items: center;
        justify-content: space-around;
        height: auto;
        padding: 15px;
        border-radius: 20px;
        position: relative;

        @include responsive(){
            border-radius: 10px;
        }
    
        .card-image{
            position: relative;
            display: block;
            width: 50%;
            min-height: 400px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            filter: grayscale(90%);

            @include responsive(){
                position: absolute;
                width: 100%;
            }

            transition: $default_transition;

            &:hover{
                filter: grayscale(0%);

                @include responsive(){
                    filter: grayscale(100%);
                }
            }

            &:hover > .card-overlay{
                background-color: rgb(27, 57, 92, 0.2);
                @include responsive(){
                    background-color: rgba(30, 30, 30, 0.85);
                }
            }

            .card-overlay{
                z-index: -3;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgb(27, 57, 92, 0.54);
                border-radius: 10px;
                transition: $default_transition;

                @include responsive(){
                    background-color: rgba(30, 30, 30, 0.85);
                }
            }
        }
    
        .card-body{
            position: relative;
            display: block;
            width: 40%;
            @include responsive(){
                width: 100%;
            }
            .card-title, .card-subtitle, .card-text{
                color: #fff;
            }
            .card-title{
                font-weight: 700;
                text-align: left;
                font-size: 18px;
            }
    
            .card-subtitle{
                .tech-label{
                    display: inline-block;
                    font-weight: 300;
                    font-size: 12px;
                    letter-spacing: 1px;
                    margin-right: 15px;
                    color: $primary_color;
                }
            }
    
            .card-text{
                z-index: 2;
                background-color: $surface;
                padding: 20px;
                border-radius: 10px;

                @include responsive(){
                    padding: 0px;
                    background-color: transparent;
                }
                
            }

            .card-text-right{
                text-align: right;
                margin-left: -30%;
                @include responsive(){
                    margin-left: 0%;
                    text-align: left;
                }
            }

            .card-text-left{
                text-align: left;
                margin-right: -30%;
                @include responsive(){
                    margin-right: 0%;
                    text-align: left;
                }
            }
    
            .card-footer{
                display: flex;
                .links{
                    display: block;
                    a{
                        color: $light;
                        transition: all ease 0.3s;
                        &:hover{
                            color: $secondary_color;
                        }

                        svg{
                            width: 24px;
                            height: 24px;
                        }

                        .github-icon{
                            fill: #fff;
                            margin-right: 10px;
                            transition: $default_transition;

                            &:hover{
                                fill: $secondary_color;
                            }
                        }
                    }

                }
            }

            .links-right{
                justify-content: flex-end;
                @include responsive(){
                    justify-content: flex-start;
                }
            }

            .links-left{
                justify-content: flex-start;
            }
        }
    }

    .card-content-left{
        flex-direction: row-reverse;
    }
}


/*
@media screen and (max-width: 715px){
    .card{
        .card-content{
            flex-direction: column !important;
    
            .card-iamge{
                width: 80% !important ;
                margin: auto;
            }
    
            .card-body{
                width: 80% !important;
            }
        }
    }
    
}*/