@import 'Variables';
@import 'mediaQueries';

.about{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    color: $light;
    max-width: $max_width;
    width: 90%;
    margin: auto;
    padding: 100px 0px;

    .content{
        max-width: 980px;
        width: 100%;
        margin: auto;
        .section-title{
            margin-top: 20px;
            h2{
                text-align: center;
                text-transform: uppercase;
                font-weight: normal;
            }
        }

        .tech-stack-list{
            display: flex;

            ul{
                list-style: none;
                padding: 0;

                &:nth-child(2), &:nth-child(3){
                    margin-left: 20px;
                }
                li{
                    margin-top: 10px;
                    font-size: 14px;
                    letter-spacing: 2px;
                    font-weight: 400;
                    &::before{
                        content: '';
                        display: inline-block;
                        height: 12px;
                        width: 12px;
                        border-radius: 100%;
                        background-color: $primary_color;
                        margin-right: 10px;
                    }
                }
            }
        }

        .about-content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include responsive(){
                flex-direction: column-reverse;
            }
            
            .personal-picture{
                background-image: url("../me_soldering.png");
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                min-width: 300px;
                min-height: 300px;
                max-width: 300px;
                max-height: 300px;
                width: 50%;
                height: 100%;
                border-radius: 100%;
            }
    
        
            .text-content{
                display: block;
                width: 50%;

                @include responsive(){
                    width: 100%;
                    margin-bottom: 20px;
                }

                p{
                    text-align: left;
                    font-weight: normal;
                }

                .highlight{
                    color: $secondary_color;
                }
            }
        }
    }
    
}