@import 'Variables.scss';

.language-select-container{
    width: 100%;
    margin: auto;
    display: block;
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;

    .language-select-content{
        max-width: $max_width;
        width: 90%;
        margin: auto;
        .optional-languages{
            max-width: 220px;
            width: 100%;
            position: absolute;
            bottom: 100px;
            transition: $default_transition;
        }

        .language-select{
            
            .current-language{
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
                background-color: $surface;
                max-width: 200px;
                padding: 10px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                span{
                    margin-left: 10px;
                    p{
                        font-weight: normal;
                        letter-spacing: 2px;
                        color: $light;
                        min-width: 100px;
                        font-size: .8rem;
                    }
                    img{
                        width: 20px;
                        transition: $default_transition;
                    }
                }
            }
        }
    }
}

.flag-img{
    width: 32px;
}

.rotate{
    transform: rotateZ(0deg);
}

.normal-pos{
    transform: rotateZ(90deg);
}


.hidden{
    margin-bottom: -250px;
    opacity: 0;
}

.show{
    margin-bottom: 0;
    opacity: 1;
}