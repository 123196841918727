@import "Variables.scss";
footer{
    display: flex;
    background: $background;
    position: relative;
    z-index: 4;

    .footer-content{
        max-width: 980px;
        width: 100%;
        padding: 40px 0px;
        margin: auto;

        .footer-text{
            color: #fff;
            letter-spacing: 1px;
            text-align: center;
        }


        .social-media{
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .social-media-item{
                background-color: rgba(30, 30, 30, 0.4);
                padding: 10px;
                border-radius: 30px;
                min-width: 40px;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: rgba(30, 30, 30, 0.8);
                    transform: translateY(-5px);
                }
    
            }
    
            a{
                font-size: 20px;
                color: $secondary_color;
                fill: $secondary_color;
                transition: all ease 0.3s;
                margin-right: 10px;
                
    
                svg{
                    fill: $secondary_color;
                    transition: $default_transition;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    
        .cls-1{
            fill: none;
            stroke:$secondary_color;
            stroke-linecap:round;
            stroke-linejoin:round;
    
        }
    }
}