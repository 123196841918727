@import 'Variables.scss';

.recent-projects{
    max-width: $max_width;
    width: 90%;
    margin: auto;
    padding: 100px 0px;
}

@media screen and (max-width:960px){
    .recent-projects{
        .recent-projects-cards{
            display: block;
        }
    }
}