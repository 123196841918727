
@import-normalize;


*, *::after, *::before{
  font-family: 'Poppins', 'sans-serif', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  box-sizing: border-box;
}

body{
  top: 0;
  padding: 0;
  margin: 0;
  background-color: #121212;
}

.container{
  max-width: 100%;
  width: 100%;
  margin: auto;
}