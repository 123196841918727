@import 'Variables.scss';



.title{
    display: flex;
    align-items: center;
    h2{
        text-align: center;
        font-weight: normal;
        margin-left: 20px;
        color: #fff;
    }

    &::before{
        content: '';
        display: block;
        width: 4px;
        height: 40px;
        background-color: $primary_color;
    }

}