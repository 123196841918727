@import 'Variables.scss';

.btn{
    display: inline-block;
    padding: 15px 20px !important;
    text-decoration: none;
    color: #000;
    border: none;
    border-radius: 40px;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 16px;
}

.btn-primary{
    background-color: $primary_color;
    color: #000;
    transition: all ease 0.2s;
    &:hover{
        text-decoration: none;
        cursor: pointer;
    }
}

.btn-secondary{
    background-color: $secondary_color;
    transition: all ease 0.2s;
    &:hover{
        text-decoration: none;
        cursor: pointer;
    }
}

.btn-red{
    background-color: $red;
    box-shadow: 0px 0px 20px $red;
}