@import 'Variables.scss';
@import 'mediaQueries.scss';

header{
    position: fixed;
    z-index: 10;
    width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    background: $navbar_background_color;
    display: flex;
    box-shadow: $shadow;
    nav{
        width: 90%;
        max-width: $max_width;
        background-color: $navbar_background_color;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: space-between;

        #menu-checkbox{
            display: none;
        }

        @include responsive(){
            #menu-checkbox:checked ~ .navbar-nav{
                left: 0%;
            }

            #menu-checkbox:checked + #menu-logo .line-1{
                transform: rotate(45deg);
                margin-top: 20px;
            }

            
            #menu-checkbox:checked + #menu-logo .line-2{
                margin-top: -6px;
                transform: rotate(-45deg);
                
                
            }
        }
        
        .brand{
            display: inline-block;

            margin-left: 10px;
            a{
                text-decoration: none;
                color: #fff;
                transition: $default_transition;
                h1{
                    font-size: 36px;
                    letter-spacing: 5px;
                    font-weight: 400;
                }
                &:hover{
                    cursor: pointer;
                    color: $primary_color;
                }
            }
        }

        .navbar-nav{
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            transition: $default_transition;

            @include responsive(){
                position: absolute;
                left: -100%;
                top: 90px;
                flex-direction: column;
                width: 100%;
                margin: 0;
                padding: 10px;
                padding-top: 20px;
                height: calc(100vh - 89.61px);
                justify-content: flex-start;
                background-color: $surface;
            }

            li{
                position: relative;
                display: inline-block;

                @include responsive(){
                    margin-top: 20px;
                }

                button{
                    text-decoration: none;
                    color: $navbar_color;;
                    display: inline-block;
                    padding: 5px 10px;
                    transition: all ease 0.2;
                    letter-spacing: 2px;
                    background: none;
                    border: none;
                    font-size: 14px;
                    transition: all ease 0.5s;
                    border-bottom: 1px solid rgba(0,0,0,0.0);
                    &:hover{
                        cursor: pointer;
                    }

                    &::after{
                        content: '';
                        position: absolute;
                        height: 4px;
                        width: 0%;
                        top: 40%;
                        left: 50%;
                        bottom: 0;
                        border-radius: 4px;
                        transition: all ease 0.6s;
                        background: $primary_color;
                        opacity: 0.8;
                    }

                    &:hover::after{
                        background: $primary_color;
                        width: 100%;
                        left: 0%;
                    }
                }
                ul{
                    display: none;
                }

                .btn-cv{
                    letter-spacing: 2px;
                    font-weight: 600;
                    text-align: center;
                    font-size: 14px;
                }

                select{
                    font-size: 16px;
                    background-color: $primary_color;
                    color: $light;
                    
                    z-index: 99;
                    
                    transition: all ease 0.5s;

                    border: solid 1px $light;
                    padding: 12px 5px;
                }
            }
            .languague-select{
                margin-right: 10px;
                border: 5px solid #000;
            }
        }

        .menu-logo{
            display: none;
            min-height: 44px;
            @include responsive(){
                display: block;
            }

            &:hover{
                cursor: pointer;
            }
            .line{
                width: 60px;
                background-color: $light;
                height: 6px;
                transform-origin: center center;
                border-radius: 10px;
            }

            .line-1{
                transition: all ease 0.6s;

            }

            .line-2{
                transition: all ease 0.6s;
            }

            .line:nth-child(2){
                margin-top: 2rem;
            }
        }
    }
}

@keyframes topBarInitialAnimation {
    from{
    }

    to{
        transform: rotate(0deg);
    }
}

@keyframes bottomBarInitialAnimation {
    from{
        transform: rotate(-45deg);
    }

    to{
        transform: rotate(0deg);
    }
}

@keyframes topBarAnimation {
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(45deg);
    }
}

@keyframes bottomBarAnimation {
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(-45deg);
    }
}