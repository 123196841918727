@import 'Variables';
@import 'mediaQueries';

.container{
    margin-top:80px
}

.hero{
    width: 100%;
    height: calc(100vh - 80px);
    background-color: $surface;
    display: flex;

    .hero-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: $max_width;
        width: 90%;
        margin: auto;

        @include responsive(){
            margin: 20px auto;
        }
    }

    .hero-info{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        @include responsive(){
            flex-direction: column;
        }

        .hero-details{
            width: 58%;
            padding: 10px;
            @include responsive(){
                width: 100%;
            }

            p{
                text-align: left;
                color: #fff;
                font-size: 16px;
            }

            .rol{
                font-size: 2.1rem;
                color: $light;
                font-weight: 700;
                letter-spacing: 5px;
                margin: 0px 0px 10px 0px;
            }

            .name{
                font-size: 2rem;
                color: $primary_color;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 5px;
                margin: 0px;
            }

            .goal{
                margin: 30px 0px;
                color: $dark_text;
                line-height: 1.4rem;
            }

            .btn-secondary{
                margin-left: 20px;
            }


        }

        .hero-img{
            min-width: 255px;
            min-height: 255px;
            background-image: url("/./linkedinProfile.jpeg");
            background-size: 100%;
            background-repeat: no-repeat;
            border-radius: 100%;

            @include responsive(){
                display: none;
            }
        }
    }

    .social-media{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        .social-media-item{
            background-color: rgba(18, 18, 18, 0.4);
            padding: 10px;
            border-radius: 30px;
            min-width: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: rgba(18, 18, 18, 0.8);
                transform: translateY(-5px);
            }

        }

        a{
            font-size: 20px;
            color: $secondary_color;
            fill: $secondary_color;
            transition: all ease 0.3s;
            margin-right: 10px;
            

            svg{
                fill: $secondary_color;
                transition: $default_transition;
                width: 30px;
                height: 30px;
            }
        }
    }

    .cls-1{
        fill: none;
        stroke:$secondary_color;
        stroke-linecap:round;
        stroke-linejoin:round;

    }
}
