@import 'Variables';
@import 'mediaQueries';

.experience{
    max-width: $max_width;
    width: 60%;
    margin: auto;
    padding: 100px 0px;
    @include responsive(){
        width: 90%;
    }

    .title{
        margin-bottom: 30px;
    }
    .job-list{
        border-left: solid 4px $primary_color;
        .job-item{
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;
            &::before{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                border: solid 4px $primary_color;
                background-color: $background;
                margin-left: -12px;
                margin-right: 20px;
            }
            
            .job-item-content{
                max-width: 90%;
                display: inline-block;
                h3, h4, h6, p{
                    color: $light;
                    margin: 0;
                }

                h3{
                    font-weight: 700;
                    font-size: 1rem;
                }

                h4{
                    margin-top: 5px;
                    font-size: 0.875rem;
                    font-weight: 400;
                    letter-spacing: 1px;
                }

                h6{
                    margin-top: 5px;
                    font-size: 0.75rem;
                    font-weight: 300;
                    letter-spacing: 1px;
                }

                p{
                    margin-top: 5px;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }
    }
}